/* eslint-disable @typescript-eslint/no-magic-numbers */
import {
  AppType,
  ConfigType,
  GraphqlMainServiceType,
  GraphqlRealtimeServiceType,
  KeysType,
  SettingsType,
} from "~/config/appConfig.d";

import {
  getEnvironment,
  isLocal,
  // isDev,
  isProduction,
  isUAT,
} from "~/utils/environment";

export const featureFlags = {
  adHocNotification: {
    base: !isProduction,
  },
  safetyEvents: {
    // FIXME: remove this flag as it is no longer needed
    base: true,
    // base:
  },
  notificationCenter: {
    base: true,
  },
  route: {
    base: true,
    bulkAssignAgents: true,
    sendOptimizationConfigsOnCreate: true,
    disableAssignedStops: false,
    sendOptimizationConfigsOnUpdate: true,
    oldReorderStops: false,
    localizeNoSolutionFoundMess: true,
    collectionPoints: {
      disable: false,
    },
  },
  trips: {
    // FIXME: remove this flag as it is no longer needed
    bulkAssignAgents: true,
    sendOptimizationConfigsOnCreate: true,
    showDelayCategory: true,
  },
  guardians: {
    // FIXME: remove this flag as it is no longer needed
    base: true,
    // FIXME: remove this flag as it is no longer needed
    cashOutRequests: true,
    // FIXME: remove this flag as it is no longer needed
    creditNote: true,
  },
  cashOutRequests: {
    // FIXME: remove this flag as it is no longer needed
    base: true,
    bulkUpdate: !isProduction && !isUAT,
  },
  schoolRegistrations: {
    locationDirection: !isProduction && !isUAT,
  },
  busAttendantPanel: {
    pncRequirementsBasicDetails: false,
    compliance: false,
    hoursWorked: false,
  },
  driverPanel: {
    pncRequirementsBasicDetails: false,
    compliance: false,
    hoursWorked: false,
  },
  leavePanel: {
    updateLeaveRequests: true,
    cancelLeaveRequests: true,
  },
  servicePanel: {
    update: {
      disable: false,
    },
    create: {
      disable: false,
    },
    zoneBased: {
      disable: true,
    },
  } as { [key: string]: { disable: boolean } },
  languageSwitcher: !isProduction && !isUAT,
  globallyDisableArchiving: true,
};

export const app: AppType = {
  appName: "Saar Main Frontend",
  userPath: "",
};

export const keys: KeysType = {
  googleMapsApi: getEnvironment().NEXT_PUBLIC_GOOGLE_MAPS_API || "",
  muiXProLicense: getEnvironment().NEXT_PUBLIC_MUI_X_PRO_LICENSE || "",
};

export const graphqlMainService: GraphqlMainServiceType = {
  hostname: getEnvironment().NEXT_PUBLIC_SERVER_URL,
  protocolHttp: getEnvironment().NEXT_PUBLIC_SERVER_HTTP_PROTOCOL || "https",
  protocolWs: getEnvironment().NEXT_PUBLIC_SERVER_WS_PROTOCOL || "wss",
  port: Number(getEnvironment().NEXT_PUBLIC_GRAPHQL_PORT),
  apiPrefix: `/${getEnvironment().NEXT_PUBLIC_GRAPHQL_PREFIX}`,
};

export const graphqlRealtimeService: GraphqlRealtimeServiceType = {
  hostname: getEnvironment().NEXT_PUBLIC_REALTIME_SERVER_URL,
  protocolHttp:
    getEnvironment().NEXT_PUBLIC_REALTIME_SERVER_HTTP_PROTOCOL || "https",
  protocolWs: getEnvironment().NEXT_PUBLIC_REALTIME_SERVER_WS_PROTOCOL || "wss",
  port: Number(getEnvironment().NEXT_PUBLIC_REALTIME_GRAPHQL_PORT),
  apiPrefix: `/${getEnvironment().NEXT_PUBLIC_REALTIME_GRAPHQL_PREFIX}`,
};

export const serviceProviderLogin = {
  hostname: getEnvironment().NEXT_PUBLIC_SP_HOST,
  protocolHttp: getEnvironment().NEXT_PUBLIC_SERVER_HTTP_PROTOCOL || "https",
  protocolWs: getEnvironment().NEXT_PUBLIC_SERVER_WS_PROTOCOL || "wss",
};

export const emuhwService = {
  hostname: "sandbox.emushrif.com", // getEnvironment().NEXT_PUBLIC_SERVER_URL,
  protocolHttp:
    getEnvironment().NEXT_PUBLIC_REALTIME_SERVER_HTTP_PROTOCOL || "https",
  protocolWs: getEnvironment().NEXT_PUBLIC_SERVER_WS_PROTOCOL || "wss",
  port: Number(getEnvironment().NEXT_PUBLIC_EMUHW_PORT),
};

export const settings: SettingsType = {
  adminPath: "/admin",
  rowsPerPage: 100,
  rowsPerPageOptions: [
    ...(isLocal ? [5, 10] : []),
    50,
    100,
    200,
    300,
    400,
    500,
  ],
  timeFormatParsing: [
    "yyyy-MM-dd'T'HH:mm:ssX",
    "yyyy-MM-dd HH:mm:ssX",
    "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSSSX",
    "yyyy-MM-dd HH:mm:ss.SSSSSSSSSX",
    "yyyy-MM-dd'T'HH:mm:ss:SSX",
    "yyyy-MM-dd'T'HH:mm:ss.sssX",
    "yyyy-MM-dd HH:mm:ss.sssX",
  ],
  defaultFetchParentsPerPage: 10,
  defaultFetchStudentsPerPage: 10,
  defaultFetchSchedulesPerPage: 10,
  defaultDateFormat: "dd/MM/yyyy",
  defaultDateFormatReverse: "yyyy/MM/dd",
  defaultDateTimeFormat: "dd/MM/yyyy HH:mm",
  defaultTimeFormat: "HH:mm",
  defaultColumnWidth: 100,
  defaultSnackBarAutoHideDuration: 6000,
  networkConnectedAutoHideDuration: 20000,
  networkDisconnectedAutoHideDuration: 100000,
  roundingDecimalPlaces: 3,
  defaultSchoolYearStartDate: "2024-08-01",
  defaultSchoolYearEndDate: "2025-06-30",
  defaultGoogleMapCloudId: "85fee1480edb5589",
};

const config: ConfigType = {
  keys,
  app,
  settings,
  graphqlMainService,
  graphqlRealtimeService,
};

export default config;
